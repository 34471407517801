import React, { useState } from "react";
import styled from "styled-components";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import Wrapper from "../../DS/Wrapper/Wrapper";
import HomeSplitImage from "../HomeSplitImage/HomeSplitImage";
import SplitImg1 from "../../assets/images/split1.jpg";
import SplitImg2 from "../../assets/images/split2.jpg";
import westand from "../../assets/images/westand.jpg";
import { useScreenClass } from "@tinyd/usescreenclass";
import WeStandFor from "../WeStandFor/WeStandFor";
import H4 from "../../DS/Typo/H4";
import DS from "../../DS/DS";
import arrowDown from "../../assets/images/downBubble.svg";
import sign from "../../assets/images/sign1.png";
import president from "../../assets/images/john.jpg";
import jill from "../../assets/images/jill.webp";
import sign2 from "../../assets/images/sign_2.png";
import newPresident from "../../assets/images/SarahHorton2023.jpg";
import signNewPresident from "../../assets/images/Sign_SarahSHorton.png";
import dotMatrix from "../../assets/images/dot_matrix1.svg";
import MembersBenefits from "../MembersBenefits/MembersBenefits";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import { Link } from "react-router-dom";

const MatrixDots = styled.img`
  position: absolute;
  left: -150px;
  bottom: 1px;
  z-index: -1;
`;

const WhoWeAreContainer = styled.div`
  position: relative;
  overflow-x: hidden;
`;

const PresidentMsgContainer = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    background-color: ${DS.colors.magenta};
    padding: 15px 25px;
    cursor: pointer;
    transition: all 0.3s ease;

    p {
      text-align: center;
    }
  }
  & > div:nth-child(2) {
    width: 1px;
    height: 113px;
    background-color: ${DS.colors.magenta};
  }
  & > div:last-child {
    background-color: ${DS.colors.lightGray};
    padding: 15px 25px;
    cursor: pointer;
    transition: all 0.3s ease;

    p {
      text-align: center;
    }
  }
`;

const PresidentDialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const PresidentDialogImgContainer = styled.div`
  padding: 24px;
  background-color: ${DS.colors.lightGray};
  display: flex;
  align-items: flex-end;
  & > * {
    flex: 1;
  }
`;

const PresidentDialogTextContainer = styled.div`
  padding: 24px;
  background-color: ${DS.colors.darkPurple};
  display: flex;
  z-index: -1;
  & > * {
    flex: 1;
  }
`;

const NewPresidentDialog = () => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);

  return (
    <PresidentDialogContainer>
      <PresidentDialogImgContainer
        style={isSmall ? { flexDirection: "column" } : {}}
      >
        <img
          src={newPresident}
          alt="new president"
          style={{
            marginBottom: isSmall ? 20 : -150,
            objectFit: "cover",
            width: "100%",
            maxWidth: 516,
          }}
        />
        <div
          style={
            isSmall
              ? {}
              : {
                paddingLeft: 35,
              }
          }
        >
          <H4>Sarah S. Horton (New president)</H4>
          <img
            src={signNewPresident}
            alt="sign"
            style={
              isSmall
                ? { width: "60%" }
                : { width: "200px" }
            }
          />
        </div>
      </PresidentDialogImgContainer>
      <PresidentDialogTextContainer>
        {!isSmall ? <div></div> : null}
        <div
          style={
            isSmall
              ? {}
              : {
                paddingLeft: 35,
              }
          }
        >
          <H3
            fontColor="white"
            style={isSmall ? { textAlign: "center" } : {}}
          >
            From the New President
          </H3>
          <H2
            fontColor="white"
            style={isSmall ? { textAlign: "center" } : {}}
          >
            New President's Message
          </H2>
        </div>
      </PresidentDialogTextContainer>
      <img
        src={arrowDown}
        alt="down"
        style={{
          height: 50,
        }}
      />
      <MatrixDots
        src={dotMatrix}
        alt="dot"
        style={
          isSmall
            ? {
              zIndex: -2,
            }
            : {}
        }
      />
    </PresidentDialogContainer>
  );
};
const PresidentDialog = () => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);

  return (
    <PresidentDialogContainer>
      <PresidentDialogImgContainer
        style={isSmall ? { flexDirection: "column" } : {}}
      >
        <img
          src={newPresident}
          alt="president"
          style={{
            marginBottom: isSmall ? 20 : -150,
            objectFit: "cover",
            width: "100%",
            maxWidth: 516,
          }}
        />
        <div
          style={
            isSmall
              ? {}
              : {
                paddingLeft: 35,
              }
          }
        >
          <H4>Sarah S. Horton (President)</H4>
          <img
            src={signNewPresident}
            alt="sign"
            style={
              isSmall
                ? { width: "60%" }
                : { width: "200px" }
            }
          />
        </div>
      </PresidentDialogImgContainer>
      <PresidentDialogTextContainer>
        {!isSmall ? <div></div> : null}
        <div
          style={
            isSmall
              ? {}
              : {
                paddingLeft: 35,
              }
          }
        >
          <H3
            fontColor="white"
            style={isSmall ? { textAlign: "center" } : {}}
          >
            From the President
          </H3>
          <H2
            fontColor="white"
            style={isSmall ? { textAlign: "center" } : {}}
          >
            President's Message
          </H2>
        </div>
      </PresidentDialogTextContainer>
      <img
        src={arrowDown}
        alt="down"
        style={{
          height: 50,
        }}
      />
      <MatrixDots
        src={dotMatrix}
        alt="dot"
        style={
          isSmall
            ? {
              zIndex: -2,
            }
            : {}
        }
      />
    </PresidentDialogContainer>
  );
};
const ExecutiveDirector = () => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);
  return (
    <PresidentDialogContainer>
      <PresidentDialogImgContainer
        style={isSmall ? { flexDirection: "column" } : {}}
      >
        <img
          src={jill}
          alt="president"
          style={{
            marginBottom: isSmall ? 20 : -150,
            objectFit: "cover",
            width: "100%",
            maxWidth: 516,
          }}
        />
        <div
          style={
            isSmall
              ? {}
              : {
                paddingLeft: 35,
              }
          }
        >
          <H4>Jill E. Jackson</H4>
          <img
            src={sign2}
            alt="sign"
            style={isSmall ? { width: "80%" } : {}}
          />
        </div>
      </PresidentDialogImgContainer>
      <PresidentDialogTextContainer>
        {!isSmall ? <div></div> : null}
        <div
          style={
            isSmall
              ? {}
              : {
                paddingLeft: 35,
              }
          }
        >
          <H3
            fontColor="white"
            style={isSmall ? { textAlign: "center" } : {}}
          >
            From the Executive Director
          </H3>
          <H2
            fontColor="white"
            style={isSmall ? { textAlign: "center" } : {}}
          >
            Executive Director's Message
          </H2>
        </div>
      </PresidentDialogTextContainer>
      <img
        src={arrowDown}
        alt="down"
        style={{
          height: 50,
        }}
      />
      <MatrixDots
        src={dotMatrix}
        alt="dot"
        style={
          isSmall
            ? {
              zIndex: -2,
            }
            : {}
        }
      />
    </PresidentDialogContainer>
  );
};

const WhoWeAre = () => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm", "md"].includes(screen);
  const [show, setShow] = useState(false);
  return (
    <WhoWeAreContainer>
      <Wrapper type="m" marginBottom="20px">
        <H3 fontColor="magenta">Who We Are</H3>
        <H2>The Voice for Education Retirees</H2>
        <Body1>
          AERA was founded in 1955 with 557 members and was
          known as the Alabama Retired Teachers Association,
          Inc. The name was changed to the Alabama Education
          Retirees Association, Inc., (AERA) in 2003 to
          better represent its membership, which includes
          all levels of public education. It was
          incorporated as an autonomous, not-for-profit
          organization and was approved by the Internal
          Revenue Service as a charitable organization in
          1965. By 1982, AERA had purchased property in
          downtown Montgomery at 828 Washington Avenue,
          raised funds to build a new headquarters building,
          and occupied it by May of that year.
        </Body1>
      </Wrapper>
      <HomeSplitImage imgs={[SplitImg1, SplitImg2]} />
      <Wrapper
        type="m"
        marginTop="100px"
        display="flex"
        justifyContent="space-between"
        style={{
          flexWrap: "wrap",
        }}
      >
        <div
          style={
            isSmall
              ? {
                width: "100%",
                marginBottom: 30,
              }
              : {
                width: "calc(50% - 20px)",
              }
          }
        >
          <Body1>
            AERA is the retired division of the Alabama
            Education Association (AEA). It is made up of 76
            local units, including five active higher
            education units. These units are divided into
            ten districts. There is at least one local unit
            in each Alabama county. AERA works closely with
            NEA-Retired and is currently the third largest
            state retiree group in NEA-Retired. AERA is also
            a partner with the National Retired Teachers
            Association (NRTA), which is a division of AARP.
          </Body1>
        </div>
        <div
          style={
            isSmall
              ? {
                width: "100%",
              }
              : {
                width: "calc(50% - 20px)",
              }
          }
        >
          <Body1>
            Each AERA district elects a district director
            who serves on the Board of Directors for a
            two-year term. Members also elect a state
            president and other state officers every two
            years. The executive director and a staff of
            three very capable full-time employees run the
            AERA State Office and serve over 22,500 members.
          </Body1>
        </div>
      </Wrapper>
      <WeStandFor bgUrl={westand} />
      {/* <Wrapper
        marginTop="100px"
        marginBottom="40px"
        display="flex"
        justifyContent="center"
      >
        <PresidentMsgContainer
          style={
            isSmall
              ? {
                flexDirection: "column",
                width: "100%",
              }
              : {}
          }
        >
          <div
            style={{
              ...(isSmall
                ? {
                  width: "65%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
                : {}),
              backgroundColor: DS.colors.magenta
            }}
          >
            <H4
              fontColor={"white"}
            >
              New President's Message
            </H4>
          </div>
        </PresidentMsgContainer>
      </Wrapper>
      <Wrapper type="m">
      <NewPresidentDialog />
            <Body1 marginTop="30px">
            Greetings! As I traveled around the state this summer attending district meetings, there was one thing that stood out to me with our members—each group wanted to be heard. We stand together with one voice to get our points across. I can’t say enough about the matter of confidence that was presented at each meeting. The conversation from our members was all the same as they received valuable information, they will need to make informed decisions concerning their pension, health insurance, and other benefits.
            <br/><br/>
            Everything has increased. That's why I am asking each member to hold legislators accountable for their actions. We can all organize around issues that concern our members. Your membership counts! Your membership matters. That’s why the new statewide community service initiative focuses on membership. Each member is asked to enroll at least two new members this year. This will ensure growth in AERA. Job titles are left behind when you retire. Whether you're a teacher, paraprofessional, principal, secretary, nurse, cook, janitor, mechanic, bus driver, etc. we're all in this together making AERA one VOICE.
            <br/><br/>
            My motto continues to be, <i>“I'm here to serve and not to be served.”</i>
            </Body1>
      </Wrapper> */}
      <Wrapper
        marginTop="100px"
        marginBottom="40px"
        display="flex"
        justifyContent="center"
      >
        <PresidentMsgContainer
          style={
            isSmall
              ? {
                flexDirection: "column",
                width: "100%",
              }
              : {}
          }
        >
          <div
            style={{
              ...(isSmall
                ? {
                  width: "65%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
                : {}),
              backgroundColor: !show
                ? DS.colors.magenta
                : DS.colors.lightGray,
            }}
          >
            <H4
              fontColor={!show ? "white" : "darkPurple"}
              onClick={() => setShow(false)}
            >
              Executive Director's Message
            </H4>
          </div>
          <div
            style={
              isSmall
                ? {
                  width: "100%",
                  height: 1,
                }
                : {}
            }
          ></div>
          <div
            style={{
              ...(isSmall
                ? {
                  width: "65%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
                : {}),
              backgroundColor: show
                ? DS.colors.magenta
                : DS.colors.lightGray,
            }}
          >
            <H4
              fontColor={show ? "white" : "darkPurple"}
              onClick={() => setShow(true)}
            >
              President's Message
            </H4>
          </div>
        </PresidentMsgContainer>
      </Wrapper>
      <Wrapper type="m">
        {show ? (
          <>
            <PresidentDialog />
            <Body1 marginTop="30px">
              This was a busy summer! AERA held nine Annual District Meetings around the state. Valuable information was presented at each of the meetings. Among the many things discussed, we are continuing our statewide community service project, “Membership! Membership! Membership!” Each member is asked to personally recruit two new members. The more members we have in the state association, the louder our voices will be when we advocate at the State House. When our Executive Director Jill Jackson and others visit legislators next year to advocate on our behalf, they need to hear us loud and clear!
              <br /><br />
              In November, we will hold our General Elections. Don't let this opportunity pass you by without having your voice heard. GET OUT AND VOTE! We must think not only of ourselves, but others. Be sure to vote your pocketbook and remember: “<i style={{ fontWeight: 300 }}>WE ARE ONE...TOGETHER WE WILL SUCCEED</i>!”
            </Body1>
          </>
        ) : (
          <>
            <ExecutiveDirector />
            <Body1 marginTop="30px">
              AERA was founded on the premise of protecting earned retiree benefits in addition to seeking additional monetary increases. AERA also advocates for the election of its association members to the TRS/PEEHIP Board of Control so members have a voice when important decisions are made affecting public pensions and health insurance. Currently, the two retired TRS board positions are held by AERA members Anita Gibson from DeKalb County and AERA Treasurer Peggy Mobley from Covington County.
              <br /><br />
              As you have read in the RSA ADVISOR, PEEHIP is navigating through recent federal changes to the Medicare Advantage insurance plans and the mandated financial changes resulting from the Inflation Reduction Act. These changes will cause astronomical increases that PEEHIP will have to incur in order to continue the robust health insurance coverage it has provided for its PEEHIP Medicare eligible members. As of this writing, the increase in costs has not affected the insured retiree. At the September PEEHIP Board of Control meeting, the board voted to: 1) request for more state funds from the Alabama Legislature in the upcoming 2025 legislative session, and 2) use money from the PEEHIP Retiree Trust.
              <br /><br />
              PEEHIP is funded by three mechanisms—the insured members, the State Legislature, and RSA investment income. For several years, PEEHIP has asked for $800 per month per active employee. This amount covers both active and retired collectively in the plan. Due to rising rates, PEEHIP will now ask for $904 per active member per month to cover the looming increases. PEEHIP will also pull money from its own reserves, the PEEHIP Retiree Trust, up to 118.9 million this coming fiscal year.
              <br /><br />
              This unforeseen health insurance spike will have to be included in the upcoming state budgets or a financial crisis could happen in the future. Stay informed and stay ready to advocate your elected officials to fully fund the requested PEEHIP funding levels. AERA will alert members when <i style={{ fontWeight: 300 }}>Call to Action measures are needed</i>.
            </Body1>
          </>
        )}
      </Wrapper>
      <MembersBenefits bgUrl={SplitImg2} />
      <Wrapper type="m" marginTop="50px"></Wrapper>
      <EmailSubscribe />

      <Wrapper type="m" marginTop="50px"></Wrapper>
    </WhoWeAreContainer>
  );
};

export default WhoWeAre;
