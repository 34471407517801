import useScroll from "@tinyd/usescroll";
import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import DS from "../../DS/DS";
import Wrapper from "../../DS/Wrapper/Wrapper";
import navigation from "../../assets/icons/location.svg";
import mobile from "../../assets/icons/mobile.svg";
import mail from "../../assets/icons/mail.svg";
import downArrow from "../../assets/icons/downArrow.svg";
import logo from "../../assets/images/logo.png";
import Caption from "../../DS/Typo/Caption";
import Caption2 from "../../DS/Typo/Caption2";
import Button from "../../DS/Button/Button";
import Body1 from "../../DS/Typo/Body1";
import { useScreenWidth } from "@tinyd/usescreensize";
import useLockBodyScroll from "@tinyd/uselockbodyscroll";

export const getHeaderHeight = () => {
  return window.innerWidth < 880 ? 70 : 80;
};

type menuItemLink = {
  url: string;
  text: string;
  subItems?: {
    url: string;
    text: string;
  }[];
};

const ribbonLeftData: (menuItemLink & {
  icon: string;
  target: string;
  imgStyle?: React.CSSProperties;
  imgMobileStyle?: React.CSSProperties;
})[] = [
  {
    target: "_blank",
    icon: navigation,
    text: "828 Washington Ave. Montgomery, AL 36104 United States",
    url: "https://www.google.com/maps/place/Alabama+Education+Retirees/@32.3760645,-86.2994017,17z/data=!3m1!4b1!4m5!3m4!1s0x888e8176025aec43:0x836d08d3f9788a14!8m2!3d32.37606!4d-86.297213",
  },
  {
    target: "_top",
    icon: mobile,
    text: "1-800-537-6867",
    url: "tel:1-800-537-6867",
    imgStyle: {
      top: "-2px",
      position: "relative",
    },
  },
  {
    target: "_top",
    icon: mail,
    text: "info@aerainc.org",
    url: "mailto:info@aerainc.org",
    imgStyle: {
      top: "2px",
      position: "relative",
    },
  },
];
const ribbonRightData: menuItemLink[] = [
  {
    text: "Click & Save",
    url: "/click_and_save",
  },
  {
    text: "Helpful links",
    url: "/helpful",
  },
  {
    text: "Events",
    url: "",
    subItems: [
      {
        text: "Advocacy Training Meetings",
        url: "https://www.aerainc.org/wp-content/uploads/2024/10/2025-AERA-Advocacy-Training-Meetings-4.png"
      }
    ],
  },
  {
    text: "The AERA Voice!",
    url: "https://www.aerainc.org/wp-content/uploads/2024/10/The-AERA-Voice-Current-Cover-Page.pdf",
  },
];

const JoinButtonData: menuItemLink = {
  url: "https://myaea.org/digital-membership-forms-5",
  text: "Join Now",
};

const menuData: (menuItemLink & {
  subMenu?: menuItemLink[];
})[] = [
  {
    text: "About Us",
    url: "/about",
    subMenu: [
      {
        text: "Who We Are",
        url: "/about/who_we_are",
      },
      {
        text: "Board of Directors",
        url: "/about/board_of_directors",
      },
      {
        text: "AER Foundation",
        url: "/about/aea_foundation",
      },
      {
        text: "State Committees",
        url: "/about/state_committees",
      },
      {
        text: "Local Units",
        url: "/about/local_units",
      },
      {
        text: "Staff",
        url: "/about/aera_staff",
      },
    ],
  },
  {
    text: "Advocacy",
    url: "/advocacy",
  },
  {
    text: "Membership",
    url: "/join",
  },
  {
    text: "Scholarships",
    url: "/scholarships",
  },
  {
    text: "Documents",
    url: "/documents",
  },
  {
    text: "Contact Us",
    url: "/contact",
  },
];

const HeaderContainer = styled.div`
  position: sticky;
  top: 0px;
  height: 120px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  background-color: ${DS.colors.white};
  transition: top 0.3s ease;
  z-index: 10;
`;

const HeaderRibbonContainer = styled.div`
  height: 40px;
  background-color: ${DS.colors.darkPurple};
  width: 100%;
  display: flex;
  align-items: center;
`;

const LinksFlex = styled.div`
  display: flex;

  a {
    display: flex;
    align-items: start;
    margin-right: 22px;

    p {
      font-weight: normal;
    }
    img {
      margin-right: 10px;
    }
  }

  @media (max-width: 1060px) {
    a:first-child {
      display: none;
    }
  }
`;
const LinksFlexRight = styled.div`
  display: flex;

  a,
  .link-container {
    display: flex;
    align-items: start;
    margin-right: 3vw;
    cursor: pointer;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    p {
      font-weight: normal;
    }
  }
`;

const MenuFlexRight = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 3vw;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 1096px) {
      margin-right: 1.5vw;
    }
  }
  & > a,
  & > div {
    height: 76px;
    padding-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    flex-direction: column;
    p {
      font-weight: 500;
      transition: all 0.3s ease;
    }
  }
  & > a:hover,
  & > div:hover {
    & > p {
      color: ${DS.colors.magenta};
    }
  }
`;

const SubMenuContainer = styled.div`
  transition: all 0.3s ease;
  background-color: ${DS.colors.white};

  position: absolute;
  left: 0;
  top: 60px;
  overflow: hidden;

  div {
    box-shadow: 0px 0px 0px 1px ${DS.colors.darkPurple};
    padding: 26px 20px 8px 20px;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
    width: 190px;
    & > a {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 18px;
    }
    & > a:hover {
      & > p {
        color: ${DS.colors.magenta};
      }
    }
  }
`;

const SubMenuItem: FC<{
  show: boolean;
  menuItems: menuItemLink[];
  path: string;
}> = ({ show = false, menuItems = [], path }) => {
  const [animState, setState] = useState(false);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setState(show);
  }, [show, setState]);
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight - 1);
      setWidth(ref.current.scrollWidth + 1);
    }
  }, [ref, setHeight]);

  return (
    <SubMenuContainer
      ref={ref}
      style={{
        opacity: animState ? 1 : 0,
        width: width,
        height: animState ? height + 2 : "0px",
      }}
    >
      <div>
        {menuItems.map((link, i) => {
          return (
            <Link to={link.url} key={i}>
              <Body1
                fontColor={
                  path.includes(link.url)
                    ? "magenta"
                    : "darkPurple"
                }
              >
                {link.text}
              </Body1>
            </Link>
          );
        })}
      </div>
    </SubMenuContainer>
  );
};
let timeoutId: any = null;
const MenuItem: FC<{
  link: menuItemLink;
  subMenuItem?: menuItemLink[];
}> = ({ link, subMenuItem = [] }) => {
  const [show, setShow] = useState(false);
  const [path, setPath] = useState(
    window.location.pathname
  );
  const history = useHistory();
  history.listen((location) => {
    setPath(location.pathname);
  });
  const hideMenu = useCallback(() => {
    timeoutId = setTimeout(() => {
      setShow(false);
    }, 300);
  }, [setShow]);
  const showMenu = useCallback(() => {
    clearTimeout(timeoutId);
    setShow(true);
  }, [setShow]);
  if (subMenuItem.length) {
    return (
      <div
        onMouseOver={() => {
          showMenu();
        }}
        onMouseLeave={(e) => {
          hideMenu();
        }}
      >
        <Body1
          fontColor={
            path.includes(link.url)
              ? "magenta"
              : "darkPurple"
          }
        >
          {link.text}
        </Body1>
        <SubMenuItem
          menuItems={subMenuItem}
          show={show}
          path={path}
        />
      </div>
    );
  }
  const isLink = link.url.startsWith("http");
  return (
    <Link
      to={isLink ? link.url : { pathname: link.url }}
      {...(isLink ? { target: "_blank" } : {})}
    >
      <Body1
        fontColor={
          path.includes(link.url) ? "magenta" : "darkPurple"
        }
      >
        {link.text}
      </Body1>
    </Link>
  );
};

const LogoImg = styled.img`
  width: 153px;
  @media (max-width: 1096px) {
    width: 100px;
  }
  @media (max-width: 940px) {
    width: 80px;
  }
`;
const MobileLogoImg = styled.img`
  width: 86px;
`;

const MobileHeaderContainer = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${DS.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 10;
`;

const HamburgerIconWrapper = styled.div`
  position: absolute;
  left: 36px;
  height: 70px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const HamburgerIconContainer = styled.div`
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;
const HamburgerLines = styled.div`
  background-color: ${DS.colors.magenta};
  width: 30px;
  height: 2px;
  transition: all 0.3s ease;
`;

let hamId: any = null;

const HamburgerIcon: FC<{
  show: boolean;
  onClick: () => any;
}> = ({ show, onClick }) => {
  const [rotateAnim, setRotation] = useState(false);
  const [size, setSize] = useState(false);

  useEffect(() => {
    clearTimeout(hamId);
    if (show) {
      setSize(true);
      hamId = setTimeout(() => {
        hamId = null;
        setRotation(true);
      }, 300);
    } else {
      setRotation(false);
      hamId = setTimeout(() => {
        hamId = null;
        setSize(false);
      }, 300);
    }
  }, [show, setRotation, setSize]);
  return (
    <HamburgerIconWrapper
      onClick={() => {
        if (hamId === null) onClick();
      }}
    >
      <HamburgerIconContainer>
        <HamburgerLines
          style={{
            transform: rotateAnim
              ? "rotateZ(-45deg)"
              : "rotateZ(0deg)",
            transformOrigin: "11px 6px",
            marginLeft: size ? "15px" : "0px",
            width: size ? "15px" : "30px",
          }}
        />
        <HamburgerLines
          style={{
            transform: rotateAnim
              ? "rotateZ(45deg)"
              : "rotateZ(0deg)",
          }}
        />
        <HamburgerLines
          style={{
            transform: rotateAnim
              ? "rotateZ(-45deg)"
              : "rotateZ(0deg)",
            transformOrigin: "4px -3px",
            width: size ? "15px" : "30px",
            marginRight: size ? "15px" : "0px",
          }}
        />
      </HamburgerIconContainer>
    </HamburgerIconWrapper>
  );
};

const MobileMenuBackdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: calc(100vh - 70px);
  top: 70px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity 0.3s ease;
  z-index: 9;
`;
const MobileMenuContainer = styled.div`
  min-height: calc(100vh - 70px - 15px);
  background-color: ${DS.colors.white};
  width: 315px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  padding-top: 15px;
  & > .mobile-menu-item,
  & > a {
    height: 60px;
    padding: 0px 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    p {
      font-weight: 500;
      transition: all 0.3s ease;
    }
    img {
      transition: all 0.3s ease;
      user-select: none;
    }
  }

  & > a:hover,
  & > .mobile-menu-item:hover {
    & > p {
      color: ${DS.colors.magenta};
    }
  }
`;

const MobileSubMenuContainer = styled.div`
  background-color: ${DS.colors.lightGray};
  box-shadow: inset 0px 10px 10px -10px rgba(0, 0, 0, 0.2),
    inset 0px -10px 10px -10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  overflow: hidden;
  & > a {
    height: 40px;
    padding: 0px 36px 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    p {
      font-weight: 500;
      transition: all 0.3s ease;
    }
    img {
      transition: all 0.3s ease;
      user-select: none;
    }
  }

  & > a:hover {
    & > p {
      color: ${DS.colors.magenta};
    }
  }
`;

const MobileSubMenuItem: FC<{
  show: boolean;
  menuItems: menuItemLink[];
}> = ({ show = false, menuItems = [] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  }, [ref, setHeight]);
  return (
    <MobileSubMenuContainer
      ref={ref}
      style={{
        height: show ? height : 0,
      }}
    >
      {menuItems.map((link, index) => {
        return (
          <Link to={link.url} key={index}>
            <Body1 fontColor="darkPurple">
              {link.text}
            </Body1>
          </Link>
        );
      })}
    </MobileSubMenuContainer>
  );
};

const MobileMenuItem: FC<{
  link: menuItemLink;
  subMenuItem?: menuItemLink[];
}> = ({ link, subMenuItem = [] }) => {
  const [show, setShow] = useState(false);
  const isLink = link.url.startsWith("http");
  if (subMenuItem.length) {
    return (
      <>
        <div
          onClick={() => {
            setShow(!show);
          }}
          className="mobile-menu-item"
        >
          <Body1 fontColor="darkPurple">{link.text}</Body1>
          <img
            src={downArrow}
            alt="arrow"
            style={{
              opacity: show ? 1 : 0.5,
              transform: show
                ? "rotateZ(-180deg)"
                : "rotateZ(0deg)",
            }}
          />
        </div>
        <MobileSubMenuItem
          show={show}
          menuItems={subMenuItem}
        />
      </>
    );
  }
  return (
    <Link
      to={isLink ? link.url : { pathname: link.url }}
      {...(isLink ? { target: "_blank" } : {})}
    >
      <Body1 fontColor="darkPurple">{link.text}</Body1>
    </Link>
  );
};

const MobileRibbonContainer = styled.div`
  background-color: ${DS.colors.darkPurple};
  padding: 18px 36px 10px 36px;

  .mobile-ribbon-top {
    a,
    .link-container {
      margin-bottom: 15px;
      display: flex;
    }
  }
  .mobile-ribbon-bottom {
    a {
      display: flex;
      margin-bottom: 20px;
      align-items: flex-start;
      img {
        margin-right: 9px;
      }
    }
  }
  .mobile-ribbon-divider {
    background-color: rgba(255, 255, 255, 0.2);
    height: 1px;
    margin-bottom: 30px;
  }
`;

const LockBody = () => {
  useLockBodyScroll();
  return null;
};
let mobMenuId: any = null;
const MobileMenu: FC<{
  show: boolean;
  onClose: () => any;
}> = ({ show, onClose }) => {
  const [width, setWidth] = useState("100%");
  const ref = useRef<HTMLDivElement>(null);
  const [showEventMenu, setShowEventMenu] = useState(false);
  useEffect(() => {
    clearTimeout(mobMenuId);
    if (!show) {
      mobMenuId = setTimeout(() => {
        setWidth("0px");
      }, 350);
    } else {
      setWidth("100%");
    }
  }, [show, setWidth]);

  return (
    <MobileMenuBackdrop
      ref={ref}
      onClick={(e) => {
        if (e.target === ref.current) {
          onClose();
        }
      }}
      style={{
        width: width,
        opacity: show ? 1 : 0,
      }}
    >
      {show ? <LockBody /> : null}
      <MobileMenuContainer
        style={{
          transform: show
            ? "translateX(0px)"
            : "translateX(-350px)",
        }}
      >
        {menuData.map((link, index) => {
          return (
            <MobileMenuItem
              key={index}
              link={{
                text: link.text,
                url: link.url,
              }}
              subMenuItem={link.subMenu}
            />
          );
        })}
        <Link
          to={{
            pathname: JoinButtonData.url,
          }}
          style={{ width: "100%", padding: 0 }}
          target="_blank"
        >
          <Button
            hoverColor="magenta"
            minWidth="100%"
            marginTop="15px"
          >
            {JoinButtonData.text}
          </Button>
        </Link>
        <MobileRibbonContainer>
          <div className="mobile-ribbon-top">
            {ribbonRightData.map((link, index) => {
              if (link.subItems && link.subItems.length) {
                return (
                  <div
                    className="link-container"
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowEventMenu((e) => !e);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Caption fontColor="white">
                        {link.text}
                      </Caption>
                      <img
                        src={downArrow}
                        alt="arrow"
                        style={{
                          width: 12,
                          filter: "brightness(100)",
                          transform: `rotate(${
                            !showEventMenu
                              ? "0deg"
                              : "180deg"
                          })`,
                        }}
                      />
                    </div>
                    {showEventMenu ? (
                      <div
                        style={{
                          paddingLeft: 12,
                        }}
                      >
                        {link.subItems.map(
                          (link, index) => {
                            return (
                              <Link
                                to={{
                                  pathname: link.url,
                                }}
                                key={index}
                                target="_blank"
                              >
                                <Caption fontColor="white">
                                  {link.text}
                                </Caption>
                              </Link>
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                );
              }
              if (link.url.includes("http")) {
                return (
                  <Link
                    to={{
                      pathname: link.url,
                    }}
                    key={index}
                    target="_blank"
                  >
                    <Caption fontColor="white">
                      {link.text}
                    </Caption>
                  </Link>
                );
              }
              return (
                <Link to={link.url} key={index}>
                  <Caption fontColor="white">
                    {link.text}
                  </Caption>
                </Link>
              );
            })}
          </div>
          <div className="mobile-ribbon-divider" />
          <div className="mobile-ribbon-bottom">
            {ribbonLeftData.map((link, index) => {
              return (
                <Link
                  to={{ pathname: link.url }}
                  key={index}
                  target={link.target}
                >
                  <img
                    src={link.icon}
                    alt={link.text}
                    style={
                      link.imgMobileStyle || link.imgStyle
                    }
                  />
                  <Caption2 fontColor="white">
                    {link.text}
                  </Caption2>
                </Link>
              );
            })}
          </div>
        </MobileRibbonContainer>
      </MobileMenuContainer>
    </MobileMenuBackdrop>
  );
};

const MobileHeader = () => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const onClick = useCallback(() => {
    setShow(!show);
  }, [setShow, show]);
  history.listen(() => {
    if (show) setShow(false);
  });
  return (
    <>
      <MobileMenu
        show={show}
        onClose={() => setShow(false)}
      />
      <MobileHeaderContainer>
        <HamburgerIcon show={show} onClick={onClick} />
        <Link to="/">
          <MobileLogoImg src={logo} alt="logo" />
        </Link>
      </MobileHeaderContainer>
    </>
  );
};

let _hideMenu: any = null;

const DesktopHeader = () => {
  const [show, setShow] = useState(true);
  const [eventShow, setEventShow] = useState(false);
  const scroll = useScroll();

  useEffect(() => {
    if (scroll.scrollY > 120) {
      if (scroll.scrollDirection === "down") {
        setShow(true);
      } else if (scroll.scrollDirection === "up") {
        setShow(false);
      }
    }
  }, [scroll, setShow]);

  return (
    <>
      <HeaderContainer
        style={{
          top: show ? "0px" : "-40px",
        }}
      >
        <HeaderRibbonContainer>
          <Wrapper
            type="xl"
            display="flex"
            justifyContent="space-between"
            flex="1"
          >
            <LinksFlex>
              {ribbonLeftData.map((link, index) => {
                return (
                  <Link
                    to={{ pathname: link.url }}
                    key={index}
                    target={link.target}
                  >
                    <img
                      src={link.icon}
                      alt={link.text}
                      style={link.imgStyle}
                    />
                    <Caption2 fontColor="white">
                      {link.text}
                    </Caption2>
                  </Link>
                );
              })}
            </LinksFlex>
            <LinksFlexRight>
              {ribbonRightData.map((link, index) => {
                if (link.subItems && link.subItems.length) {
                  return (
                    <div
                      className="link-container"
                      key={index}
                      onMouseOver={() => {
                        setEventShow(true);
                        clearTimeout(_hideMenu);
                      }}
                      onMouseLeave={() => {
                        _hideMenu = setTimeout(() => {
                          setEventShow(false);
                        }, 300);
                      }}
                    >
                      <Caption fontColor="white">
                        {link.text}
                      </Caption>
                      {eventShow ? (
                        <div
                          onMouseOver={() => {
                            setEventShow(true);
                            clearTimeout(_hideMenu);
                          }}
                          onMouseLeave={() => {
                            _hideMenu = setTimeout(() => {
                              setEventShow(false);
                            }, 300);
                          }}
                          style={{
                            position: "absolute",
                            backgroundColor:
                              DS.colors.white,
                            boxShadow: `0px 0px 0px 1px ${DS.colors.darkPurple}`,
                            padding: 16,
                            top: 24,
                            display: "flex",
                            flexDirection: "column",
                            gap: 12,
                            zIndex: 1,
                            minWidth: 200,
                          }}
                        >
                          {link.subItems.map(
                            (link, index) => {
                              return (
                                <Link
                                  to={{
                                    pathname: link.url,
                                  }}
                                  key={index}
                                  target="_blank"
                                >
                                  <Caption fontColor="darkPurple">
                                    {link.text}
                                  </Caption>
                                </Link>
                              );
                            }
                          )}
                        </div>
                      ) : null}
                    </div>
                  );
                }
                if (link.url.includes("http")) {
                  return (
                    <Link
                      to={{
                        pathname: link.url,
                      }}
                      key={index}
                      target="_blank"
                    >
                      <Caption fontColor="white">
                        {link.text}
                      </Caption>
                    </Link>
                  );
                }
                return (
                  <Link to={link.url} key={index}>
                    <Caption fontColor="white">
                      {link.text}
                    </Caption>
                  </Link>
                );
              })}
            </LinksFlexRight>
          </Wrapper>
        </HeaderRibbonContainer>
        <Wrapper
          type="xl"
          display="flex"
          justifyContent="space-between"
          flex="1"
          alignItems="center"
          height="80px"
        >
          <Link to="/">
            <LogoImg src={logo} alt="logo" />
          </Link>
          <MenuFlexRight>
            {menuData.map((item, index) => {
              return (
                <MenuItem
                  link={{
                    url: item.url,
                    text: item.text,
                  }}
                  key={index}
                  subMenuItem={item.subMenu}
                />
              );
            })}
            <Link
              target="_blank"
              to={{
                pathname: JoinButtonData.url,
              }}
            >
              <Button hoverColor="magenta">
                {JoinButtonData.text}
              </Button>
            </Link>
          </MenuFlexRight>
        </Wrapper>
      </HeaderContainer>
    </>
  );
};

const Header = () => {
  const width = useScreenWidth();

  if (width < 880) {
    return <MobileHeader />;
  }
  return <DesktopHeader />;
};

export default Header;
