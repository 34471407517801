import { useScreenClass } from "@tinyd/usescreenclass";
import React, { FC } from "react";

import styled from "styled-components";
import DS from "../../DS/DS";
import Body1 from "../../DS/Typo/Body1";
import H4 from "../../DS/Typo/H4";
import navigation from "../../assets/icons/location_red.svg";
import mobile from "../../assets/icons/mobile_dark_red.svg";
import email from "../../assets/icons/mail_dark_red.svg";
import { Link } from "react-router-dom";

const InfoCardContainer = styled.div`
  background-color: ${DS.colors.white};
  padding: 35px 20px 35px 20px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.16),
    inset 0 3px 0px ${DS.colors.magenta};
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.2),
      inset 0 8px 0px ${DS.colors.magenta};
  }
`;

const LinkContainer = styled.div`
  margin-top: 10px;
  & > a,
  & > div {
    display: flex;
    margin-bottom: 10px;
    img {
      margin-right: 12px;
      width: 14px;
      height: 16px;
      margin-top: 5px;
    }
  }
`;

const CustomLink = styled(Link)`
  color: inherit;
`;

const InfoCard: FC<{
  heading: string;
  title: string;
  phone: string[];
  mail: string[];
  address?: string;
}> = ({
  heading,
  title,
  phone,
  mail,
  address = "",
}) => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);

  return (
    <InfoCardContainer
      style={
        isSmall
          ? {
              width: "calc(100% / 1) ",
              marginBottom: 30,
            }
          : {
              width: "calc(100% / 3 - 70px)",
              marginBottom: 55,
              marginRight: 30,
            }
      }
    >
      <Body1
        fontColor="magenta"
        style={{ textTransform: "uppercase" }}
      >
        {heading}
      </Body1>
      <H4 style={{ fontWeight: 800 }}>{title}</H4>
      <LinkContainer>
        {phone.length ? (
          <div>
            <img src={mobile} alt={"phone"} />
            <Body1>
              {phone.map((_phone, index) => {
                return (
                  <CustomLink
                    to={{
                      pathname: `tel:${_phone}`,
                    }}
                    key={index}
                    target="_top"
                  >
                    {_phone}
                    {index + 1 < phone.length
                      ? ", "
                      : ""}
                  </CustomLink>
                );
              })}
            </Body1>
          </div>
        ) : null}
        {mail.length ? (
          <div>
            <img src={email} alt={"mail"} />
            <Body1>
              {mail.map((_mail, index) => {
                return (
                  <CustomLink
                    to={{
                      pathname: `mailto:${_mail}`,
                    }}
                    key={index}
                    target="_top"
                  >
                    {_mail}
                    {index + 1 < mail.length
                      ? ", "
                      : ""}
                  </CustomLink>
                );
              })}
            </Body1>
          </div>
        ) : null}
        {/* {mail.length ? mail.map((_mail, index) => {
                return (
          <Link
            to={{ pathname: `mailto:${_mail}` }}
            target="_top"
          >
            <img src={email} alt={"mail"} />
            <Body1
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {_mail}
            </Body1>
          </Link>
        )}) : null} */}
        {address.length ? (
          <div>
            <img src={navigation} alt={"map"} />
            <Body1>{address}</Body1>
          </div>
        ) : null}
      </LinkContainer>
    </InfoCardContainer>
  );
};

export default InfoCard;
